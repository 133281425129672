var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NavHeader'),_c('NavHeaderMobile'),_c('div',{staticClass:"wrapper"},[_c('h2',[_vm._v("Reset Password")]),_c('div',{staticClass:"register-container"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"E-mail"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              {
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not valid email!',
                  },
                  {
                    required: true,
                    message: 'Please enter your email!',
                  },
                ],
              },
            ]),expression:"[\n              'email',\n              {\n                rules: [\n                  {\n                    type: 'email',\n                    message: 'The input is not valid email!',\n                  },\n                  {\n                    required: true,\n                    message: 'Please enter your email!',\n                  },\n                ],\n              },\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":"New Password","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please enter your password!',
                  },
                  {
                    validator: _vm.validateToNextPassword,
                  },
                ],
              },
            ]),expression:"[\n              'password',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Please enter your password!',\n                  },\n                  {\n                    validator: validateToNextPassword,\n                  },\n                ],\n              },\n            ]"}],attrs:{"type":"password"}})],1),_c('a-form-item',{attrs:{"label":"Confirm Password","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'confirm',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  {
                    validator: _vm.compareToFirstPassword,
                  },
                ],
              },
            ]),expression:"[\n              'confirm',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Please confirm your password!',\n                  },\n                  {\n                    validator: compareToFirstPassword,\n                  },\n                ],\n              },\n            ]"}],attrs:{"type":"password"},on:{"blur":_vm.handleConfirmBlur}})],1),_c('a-form-item',{attrs:{"label":"Email Verification Code"}},[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":18}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'email-code',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter the captcha you got!',
                      },
                    ],
                  },
                ]),expression:"[\n                  'email-code',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter the captcha you got!',\n                      },\n                    ],\n                  },\n                ]"}]})],1),_c('a-col',{attrs:{"span":6}},[_c('a-button',{staticClass:"goToBtn",attrs:{"type":"primary","loading":_vm.sendEmailLoading,"disabled":_vm.sendEmailDisabled},on:{"click":_vm.getCaptcha}},[_vm._v(_vm._s(_vm.sendEmailText))])],1)],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"goToBtn",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Confirm ")])],1)],1)],1)]),_c('NewFooter')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }