<template>
  <div>
    <NavHeader />
    <NavHeaderMobile />
    <div class="wrapper">
      <h2>Reset Password</h2>
      <div class="register-container">
        <a-form :form="form" @submit="handleSubmit">
          <a-form-item label="E-mail">
            <a-input
              v-decorator="[
                'email',
                {
                  rules: [
                    {
                      type: 'email',
                      message: 'The input is not valid email!',
                    },
                    {
                      required: true,
                      message: 'Please enter your email!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="New Password" has-feedback>
            <a-input
              v-decorator="[
                'password',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your password!',
                    },
                    {
                      validator: validateToNextPassword,
                    },
                  ],
                },
              ]"
              type="password"
            />
          </a-form-item>
          <a-form-item label="Confirm Password" has-feedback>
            <a-input
              v-decorator="[
                'confirm',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    {
                      validator: compareToFirstPassword,
                    },
                  ],
                },
              ]"
              type="password"
              @blur="handleConfirmBlur"
            />
          </a-form-item>
          <a-form-item label="Email Verification Code">
            <a-row :gutter="8">
              <a-col :span="18">
                <a-input
                  v-decorator="[
                    'email-code',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the captcha you got!',
                        },
                      ],
                    },
                  ]"
                />
              </a-col>
              <a-col :span="6">
                <a-button
                  class="goToBtn"
                  type="primary"
                  @click="getCaptcha"
                  :loading="sendEmailLoading"
                  :disabled="sendEmailDisabled"
                  >{{ sendEmailText }}</a-button
                >
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item>
            <a-button class="goToBtn" type="primary" html-type="submit">
              Confirm
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <NewFooter />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
import NavHeaderMobile from '@/components/NavHeaderMobile.vue';
import NewFooter from '@/components/NewFooter.vue';
import { sendEmail, resetPasswordFun } from '@/utils/api';

export default {
  name: 'ResetPassword',
  components: {
    NavHeader,
    NavHeaderMobile,
    NewFooter,
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'coordinated' }),
      mail_code_token: '',
      sendEmailText: 'Send',
      timer: null,
      defaultTime: 60,
      sendEmailLoading: false,
      sendEmailDisabled: false,
    };
  },
  created() {},
  methods: {
    getCaptcha() {
      if (!this.form.getFieldValue('email')) {
        this.$message.error('Please enter your email');
      } else {
        this.sendEmailLoading = true;
        this.sendEmailDisabled = true;
        const params = {
          mail: this.form.getFieldValue('email'),
        };
        sendEmail(params)
          .then((res) => {
            this.mail_code_token = res.result;
            this.sendEmailLoading = false;
            this.$message.success('send email success');
            this.timer = setInterval(this.CountDown, 1000);
          })
          .catch(() => {
            this.sendEmailLoading = false;
            this.sendEmailDisabled = false;
          });
      }
    },
    CountDown() {
      if (this.defaultTime > 0) {
        this.defaultTime = this.defaultTime - 1;
        this.sendEmailText = this.defaultTime;
      } else {
        clearInterval(this.timer);
        this.defaultTime = 60;
        this.sendEmailDisabled = false;
        this.sendEmailText = 'Send';
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            mail: values.email,
            mail_code_token: this.mail_code_token,
            new_password: values.password,
          };
          resetPasswordFun(params, values['email-code']).then((res) => {
            this.$message.success(res.msg);
            this.$router.push('/resetPasswordSuccess');
          });
        }
      });
    },
    handleConfirmBlur(e) {
      const { value } = e.target;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const { form } = this;
      if (value && value !== form.getFieldValue('password')) {
        callback('Two passwords that you enter is inconsistent!');
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const { form } = this;
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true });
      }
      callback();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 600px;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 20px;
  min-height: calc(100vh - 180px);
  h2 {
    font-size: 40px;
    line-height: 56px;
    margin-top: 20px;
  }
  .register-container {
    margin-top: 60px;
    padding: 20px 60px;
    border-radius: 10px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
    .ant-input {
      height: 48px;
    }
    .goToBtn {
      border-radius: 8px;
      height: 48px;
      width: 100%;
    }
  }
  @media (max-width: 960px) {
    width: 100%;
    flex-direction: column;
    .register-container {
      padding: 20px;
    }
  }
}
</style>
